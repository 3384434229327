<template>
  <el-dialog
    class="timeslot-left-dialog"
    title="Внимание"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="close"
  >
    <BillInnerModal
      :type-bill="typeBill"
      :inner-visible="innerVisible"
      @close="innerVisible = false"
    />
    <span>
      На вашем балансе
      <span style="color: #4ca7d8; font-weight: 700">
        {{ timeslotsLeft }}
      </span>
      таймслотов<span v-if="!addBalaceRequest">
        , для бронирования
        <span style="color: #4ca7d8; font-weight: 700">
          {{ requestsCount }}
        </span>
        таймслотов необходимо оплатить еще
        <span style="color: #f56c6c; font-weight: 700">
          {{ requestsCount - timeslotsLeft - 3 }}
        </span>
      </span>
    </span>
    <span slot="footer" class="dialog-footer">
      <iq-button
        v-if="!addBalaceRequest"
        full-width
        color="second"
        size="small"
        @onClick="close"
      >
        Изменить запрос
      </iq-button>
      <div class="timeslots-left__footer">
        <iq-button
          full-width
          type="primary"
          size="small"
          @onClick="submitBillPay"
        >
          Cчет на оплату
        </iq-button>
        <iq-button
          full-width
          type="primary"
          size="small"
          @onClick="submitOnlinePay"
        >
          Пополнить онлайн
        </iq-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import BillInnerModal from '../modals/BillInnerModal'
import IqButton from '@/UI/buttons/IqButton'

export default {
  components: { IqButton, BillInnerModal },
  props: {
    timeslotsLeft: {
      type: Number,
      required: true,
    },
    requestsCount: {
      type: Number,
      required: true,
    },
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    addBalaceRequest: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      typeBill: '',
      innerVisible: false,
      confirmDelete: false,
    }
  },
  methods: {
    ...mapActions({}),
    submitOnlinePay() {
      this.innerVisible = true
      this.typeBill = 'online'
    },
    submitBillPay() {
      this.innerVisible = true
      this.typeBill = 'bill'
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="sass">
$bg-modal: rgba(225, 225, 225, 0.82)
$bg-modal-header: #4CA7D8
$white: #fff
.timeslot-left-dialog
  background: $bg-modal
  .el-dialog
    border-radius: 4px!important
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1) !important
    @media(max-width: 1120px)
      width: 50%!important
    @media(max-width: 768px)
      width: 80%!important
    @media(max-width: 480px)
      width: 90%!important
  .el-dialog__body
    padding: 12px 15px!important
  .dialog-footer
    display: flex
    flex-direction: column
    flex-wrap: nowrap
    align-items: center
    justify-content: space-between
    @media(max-width: 420px)
      flex-wrap: wrap
  .el-button
    @media(max-width: 420px)
      margin-left: 0px!important
      margin-bottom: 10px!important
      margin-top: 10px!important
  .el-dialog__headerbtn
    top: 13px!important
    right: 10px!important
  .el-dialog__close
    font-size: 24px!important
    color: $white!important
  .el-dialog__header
    background: $bg-modal-header!important
    padding: 12px 15px!important
    border-radius: 4px 4px 0px 0px!important
    > span
      font-style: normal!important
      font-weight: 500!important
      font-size: 18px!important
      line-height: 26px!important
      color: $white!important

.timeslots-left__footer
  display: flex
  justify-content: space-between
  align-items: center
  margin: 12px 15px 0px
  width: 100%
  @media (max-width: 1199px)
    flex-direction: column
    margin: 12px 0 0
</style>
